// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAOfJn81JWbNUoZIGfxWl1zWz19R_AGC08",
    authDomain: "whats-it-worth-50f65.firebaseapp.com",
    projectId: "whats-it-worth-50f65",
    storageBucket: "whats-it-worth-50f65.appspot.com",
    messagingSenderId: "798648752808",
    appId: "1:798648752808:web:1b9958eda6ab362a62009d",
    measurementId: "G-39CX8ZCQN0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
