import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';



export default function ResponsiveDialog({ open, setOpen }) {
    const { width, height } = useWindowSize();
    const isMobile = width < 768;
    const isShortScreen = height < 700;
    const navigate = useNavigate();


    const handleClose = (reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpen(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '1rem',
                    padding: '1rem',
                    background: "linear-gradient(135deg, #e0f7fa, #ffe5e0)",
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70%",
                    overflowY: "visible",
                },
                '& .MuiDialogTitle-root': {
                    fontFamily: 'Playfair Display, serif',
                    fontSize: `${isMobile ? '1.5rem' : '2.5rem'}`,
                    textAlign: "center",
                }, '& .MuiDialogContent-root': {
                    fontFamily: 'Playfair Display, serif',
                    fontSize: `${isMobile ? '.5rem' : '1rem'}`,
                    textAlign: "left",
                    paddingX: `${isShortScreen ? ".5rem" : "1rem"}`,
                    marginY: `${isShortScreen ? " " : "1rem"}`,
                    marginX: 'auto',
                    display: 'grid',
                    '& div': {
                        display: 'inline-flex',
                        marginBottom: '.5rem'
                    },
                },
                '& .MuiDialogContentText-root': {
                    fontFamily: 'Playfair Display, serif',
                    fontSize: `${isShortScreen ? '.75rem' : isMobile ? '1rem' : '1.25rem'}`,
                    textAlign: "left",
                    marginX: 'auto',
                    color: 'black',
                    marginLeft: '1rem',
                },
                '& .MuiButtonBase-root': {
                    fontFamily: 'Playfair Display, serif',
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem, 2rem",
                    background: "#fff7ed",
                    color: "black",
                    borderRadius: "1rem",
                    fontSize: `${isMobile ? '1rem' : '2rem'}`,

                },
            }}
        >
            <DialogTitle id="responsive-dialog-title" className="font-serif">
                {"Create An Account To See Appraisal"}
            </DialogTitle>
            <DialogContent>
                <div>
                    <CheckIcon /> <DialogContentText><strong>Never throw away something that’s valuable.</strong></DialogContentText>
                </div>
                <div>
                    <CheckIcon /> <DialogContentText>Learn if <strong>any</strong> item is worth your money or not.</DialogContentText>
                </div>
                <div>
                    <CheckIcon /> <DialogContentText>Trained on the most reputable data sources to assess <strong>material quality, carbon footprint</strong>, current and future <strong>market demand</strong>, and more.</DialogContentText>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`font-serif flex text-2xl justify-center items-center ml-2 py-3 px-6 rounded-full text-black text-center bg-white md:px-6 mt-6`}
                    onClick={() => navigate("/login")}
                >
                    Create An Account
                </Button>
            </DialogActions>
        </Dialog >
    );
}
