import React, { useState, useMemo } from 'react';
import { Collapse, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import exampleResults from "./exampleresults.json";
import useWindowSize from '../../hooks/useWindowSize';

function ExampleResult({ exampleId, image }) {
    const [openItems, setOpenItems] = useState({});
    const { width } = useWindowSize();
    const isMobile = width < 760;

    const currentExample = useMemo(() => {
        return exampleResults.find(example => example.id === exampleId) || exampleResults[0];
    }, [exampleId]);

    const handleClick = (index) => {
        setOpenItems(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className={`${!isMobile && 'p-8 pb-0'}`}>
            <div className="relative">
                <img
                    src={image}
                    className="object-contain max-w-full max-h-[70vh] rounded mx-auto shadow"
                    alt={exampleId}
                />
            </div>
            <Typography
                className={`font-serif text-black ${isMobile ? 'text-2xl pb-4' : 'text-4xl pb-12'} px-2`}
                dangerouslySetInnerHTML={{ __html: currentExample.title }}
            />
            <Typography
                className={`font-serif text-black ${isMobile ? "text-sm" : "text-lg"} px-1`}
                dangerouslySetInnerHTML={{ __html: currentExample.introduction }}
            />
            <List>
                {currentExample.sections.map((item, index) => (
                    <React.Fragment key={index}>
                        <ListItemButton onClick={() => handleClick(index)}>
                            <ListItemText
                                primary={
                                    <Typography className={`font-display ${!isMobile ? "text-xl" : ''}`} dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                                }
                            />
                            {openItems[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openItems[index]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText
                                        primary={
                                            <Typography className="font-serif" dangerouslySetInnerHTML={{ __html: item.content }} />
                                        }
                                    />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </React.Fragment>
                ))}
            </List>
        </div >
    );
}

export default ExampleResult;