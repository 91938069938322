import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import useWindowSize from '../hooks/useWindowSize';

function PdfViewer({ pdfURL }) {
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const { GoToPreviousPage, GoToNextPage, CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;
    const { width } = useWindowSize();
    const isMobile = width < 768;
    return (
        <div style={{
            height: isMobile ? '70dvh' : '80dvh',
            width: isMobile ? '100dvw' : '90dvw',
            display: 'flex',
            flexDirection: 'column',
            margin: isMobile ? '0' : '0 auto'
        }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: isMobile ? '5px' : '10px',
                        backgroundColor: '#f0f0f0',
                        borderBottom: '1px solid #ddd'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: isMobile ? '5px' : '0' }}>
                        <GoToPreviousPage>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#fff',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        marginRight: '5px'
                                    }}
                                    disabled={props.isDisabled}
                                >
                                    Previous
                                </button>
                            )}
                        </GoToPreviousPage>
                        <GoToNextPage>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#fff',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        marginLeft: '5px'
                                    }}
                                    disabled={props.isDisabled}
                                >
                                    Next
                                </button>
                            )}
                        </GoToNextPage>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CurrentPageInput /> of <NumberOfPages />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ZoomOutButton />
                        <ZoomPopover />
                        <ZoomInButton />
                    </div>
                </div>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <Viewer
                        fileUrl={pdfURL}
                        plugins={[pageNavigationPluginInstance, zoomPluginInstance]}
                        defaultScale={isMobile ? 0.5 : 1.2}
                        pageLayout="singlePage"
                    />
                </div>
            </Worker>
        </div>
    );
}

export default PdfViewer;