import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './App.css';
import Header from './components/Header';
import LandingPage from './Pages/Landing';
import LoadingView from './components/Loading';
import LoginForm from './components/Forms/LoginForm';
import UserProfile from './Pages/Profile';
import PrivacyPolicy from './Pages/Privacy/Privacy';
import TermsOfService from './Pages/Terms';
import PaymentPage from './Pages/Payment';
import Analyzer from './Pages/Analyzer';
import Example from './Pages/Example';
import { getPhoto } from './utils/cacheManager';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <LoadingView />;
  if (!user) return <Navigate to="/login" replace />;
  return children;
};

const PaidRoute = ({ children }) => {
  const { user, isPaidAccount, loading, subscriptionStatus } = useAuth();
  const location = useLocation();

  if (loading) return <LoadingView />;
  if (!user) return <Navigate to="/login" replace />;
  if (!isPaidAccount && subscriptionStatus !== 'cancelled') return <Navigate to="/payment" replace />;
  if (subscriptionStatus === 'cancelled' && location.pathname !== '/payment') {
    return <Navigate to="/payment" replace />;
  }
  return children;
};

function AppRoutes({ storedPhoto, handleSetStoredPhoto }) {
  const { user, isPaidAccount, loading } = useAuth();
  const location = useLocation();

  if (loading) return <LoadingView />;

  // Redirect logged-in users with paid accounts from "/" to "/analyze"
  if (user && isPaidAccount && location.pathname === '/') {
    return <Navigate to="/analyze" replace />;
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage isAuthenticated={!!user} isPaid={isPaidAccount} storedPhoto={storedPhoto} setStoredPhoto={handleSetStoredPhoto} />} />
      <Route path="/login" element={user ? <Navigate to={isPaidAccount ? "/analyze" : "/payment"} replace /> : <LoginForm />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/example/:exampleId" element={<Example isAuthenticated={!!user} isPaid={isPaidAccount} setStoredPhoto={handleSetStoredPhoto} />} />
      <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
      <Route path="/payment" element={<ProtectedRoute><PaymentPage storedPhoto={storedPhoto} /></ProtectedRoute>} />
      <Route path="/analyze" element={<PaidRoute><Analyzer storedPhoto={storedPhoto} setStoredPhoto={handleSetStoredPhoto} /></PaidRoute>} />
    </Routes>
  );
}


function App() {
  const [storedPhoto, setStoredPhoto] = useState(null);
  const { user, loading, logout, isPaidAccount } = useAuth();

  useEffect(() => {
    const loadStoredPhoto = async () => {
      try {
        const storedPhotoInfo = localStorage.getItem('storedPhotoInfo');
        if (storedPhotoInfo) {
          const parsedInfo = JSON.parse(storedPhotoInfo);
          if (parsedInfo && parsedInfo.url) {
            const photoBlob = await getPhoto(parsedInfo.url);
            if (photoBlob) {
              setStoredPhoto({
                ...parsedInfo,
                blob: photoBlob
              });
            } else {
              console.log('Failed to load photo blob');
              setStoredPhoto(null);
            }
          } else {
            console.log('Invalid stored photo information');
            setStoredPhoto(null);
            localStorage.removeItem('storedPhotoInfo');
          }
        } else {
          setStoredPhoto(null);
        }
      } catch (error) {
        console.error('Error loading stored photo:', error);
        setStoredPhoto(null);
        localStorage.removeItem('storedPhotoInfo');
      }
    };
    loadStoredPhoto();
  }, []);

  const handleSetStoredPhoto = (newPhotoInfo) => {
    if (newPhotoInfo) {
      setStoredPhoto(newPhotoInfo);
      localStorage.setItem('storedPhotoInfo', JSON.stringify(newPhotoInfo));
    } else {
      setStoredPhoto(null);
      localStorage.removeItem('storedPhotoInfo');
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('storedPhotoInfo');
      localStorage.removeItem('analysisResult');
      setStoredPhoto(null);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="App min-h-screen flex flex-col">
      <Header onLogout={handleLogout} isAuthenticated={!!user} setPhoto={handleSetStoredPhoto} isPaidAccount={isPaidAccount} />
      <main className="flex-grow pt-20 w-100 container">
        {loading ? <LoadingView /> : <AppRoutes storedPhoto={storedPhoto} handleSetStoredPhoto={handleSetStoredPhoto} />}
      </main>
    </div>

  );
}

export default App;