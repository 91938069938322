import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function GradientCircularProgress() {
    return (
        <React.Fragment>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#9ed3da" />
                        <stop offset="100%" stopColor="#db9a8d" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size="8rem" />
        </React.Fragment>
    );
}

export default function LoadingView() {
    return (
        <Box className="container">
            <GradientCircularProgress />
        </Box>
    );
}