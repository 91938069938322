import { Box, Button, Link, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserProfile, manageSubscription } from '../../api';
import LoadingView from '../../components/Loading';
import useWindowSize from '../../hooks/useWindowSize';

const UserProfile = () => {
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const isMobile = width < 768;


    useEffect(() => {

        const getUserData = async () => {
            try {
                const userData = await fetchUserProfile();
                setUserProfile(userData);
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        getUserData();

    }, []);

    const handleManageSubscription = async () => {
        setOpenDialog(true);
    };

    const handleConfirmRedirect = async () => {
        setOpenDialog(false);
        try {
            await manageSubscription();
            // Redirection will be handled by manageSubscription function
        } catch (error) {
            setError(error);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // const deleteAccount = async () => {
    //     try {
    //         const wasDeleted = await handleDeleteAccount();
    //         if (wasDeleted) {
    //             navigate('/login');
    //         }
    //         // If wasDeleted is false, the user stays on the current page
    //     } catch (error) {
    //         setError(error);
    //     }
    // };

    const isLifetime = userProfile?.subscription_plan === "Lifetime Access";


    if (loading) return <div className='container'><LoadingView /></div>;

    return (
        <div className="flex flex-col">
            <div className={`flex-grow ${isMobile ? '' : 'pt-20'} pb-16`}>
                {error ? (
                    <div>
                        <Typography className='font-serif p-4 text-red-500'>Error: {error.message}</Typography>
                        <Button variant="outlined"
                            className="text-sm md:text-base text-black font-serif border-black mt-8"
                            onClick={() => setError(null)}>Back to Profile
                        </Button>
                    </div>
                ) : (
                    <Box className='mt-10'>
                        <Box className="bg-white rounded-full p-4 max-w-md mx-auto mb-6 shadow-lg">
                            <Typography variant="h4" className="font-display mb-4">User Profile</Typography>
                            {!userProfile ?
                                (<> <Typography variant="subtitle" className="font-serif">No user profile found.</Typography>
                                    <Typography className="font-serif body1">Need Help? Message us at </Typography>
                                    <Link className='font-serif text-black' href="mailto:hello@whatsitworth.xyz">hello@whatsitworth.xyz</Link>
                                </>) :
                                (
                                    <>
                                        <Typography className="font-serif text-sm md:text-base mb-2">Email: {userProfile.email}</Typography>
                                        <Typography className="font-serif text-sm md:text-base mb-2">
                                            Subscription Status: {userProfile.subscription_status ? 'Active' : 'Inactive'}
                                        </Typography>
                                        <Typography className="font-serif text-sm md:text-base mb-2">
                                            Current Plan: {userProfile.subscription_plan || 'None'}
                                        </Typography>
                                    </>
                                )
                            }
                        </Box>
                        {!isLifetime && userProfile &&
                            <Box className="mb-6 bg-white rounded-full p-4 md:p-8 max-w-md mx-auto shadow-lg">
                                <Typography variant="h5" className="font-display mb-4">Manage Subscription</Typography>
                                <Box className="space-y-2">
                                    {userProfile.subscription_plan ? (

                                        <Button
                                            variant="outlined"
                                            onClick={handleManageSubscription}
                                            className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                        >Change or Cancel Subscription
                                        </Button>

                                    ) : !userProfile.subscription_plan ? (
                                        <>
                                            <Button
                                                variant="outlined"
                                                onClick={() => navigate('/payment')}
                                                className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                            >
                                                Choose a Plan
                                            </Button>
                                        </>) : (null)

                                    }
                                </Box>
                            </Box>
                        }
                        <Box className="mb-6 bg-white rounded-full p-4 max-w-md mx-auto shadow-lg text-center">
                            <Typography className='font-display text-black'> Any questions? Email Us </Typography>
                            <Link
                                className='font-serif text-black'
                                href="mailto:hello@whatsitworth.xyz"
                                sx={{
                                    textDecoration: 'underline',
                                    textDecorationColor: 'black'
                                }}
                            >
                                hello@whatsitworth.xyz
                            </Link>
                        </Box>


                        {/* {userProfile &&
                            <Box className="mb-6 bg-white rounded-full p-4 md:p-8 max-w-md mx-auto shadow-lg">
                                <Typography variant="h5" className="font-display mb-4">Account Management</Typography>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={deleteAccount}
                                    className="text-sm md:text-base font-serif w-3/4"
                                >
                                    Delete Account
                                </Button>
                            </Box>
                        } */}
                        {error && <Typography color="error" className="mt-4">{error.message}</Typography>}
                    </Box>
                )}
            </div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className='font-display'>{"Redirect to Stripe"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='font-serif'>
                        You will be redirected to Stripe's secure portal to manage your subscription. Do you want to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" className="font-display">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmRedirect} color="primary" className="font-display" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            <footer className={`py-4 ${isMobile ? 'fixed bottom-0 left-0 right-0' : ''}`}>
                <Box className='flex justify-center items-center text-center space-x-2 mt-2'>
                    <Link className='font-serif text-slate-500' underline='hover' variant="body2" href="/terms-of-service">
                        Terms
                    </Link>
                    <Typography className='font-serif'>|</Typography>
                    <Link className='font-serif text-slate-500' underline='hover' variant="body2" href="/privacy-policy">
                        Privacy
                    </Link>
                </Box>
            </footer>
        </div >

    );
};

export default UserProfile;