import React, { useEffect } from 'react';
import { Button, Box, } from '@mui/material';
import useWindowSize from '../hooks/useWindowSize';
import ResponsiveDialog from './Dialog';

const UploadView = ({ isAuthenticated, photoPreview, handleCancel, handleSeeAppraisal, openDialog, setOpenDialog, showButton, setShowButton, cancel, setCancel, }) => {
    const { height } = useWindowSize();
    const isShortScreen = height < 700;

    useEffect(() => {
        setTimeout(() => {
            setShowButton(true);
            if (!isAuthenticated) {
                setOpenDialog(true);
            }
        }, 5000);

        setTimeout(() => {
            setCancel(true);
        }, 5000);
    }, [isAuthenticated, setCancel, setOpenDialog, setShowButton])


    return (
        <div>
            <Box id="photo-container" className="container mx-auto my-0 flex flex-col items-center justify-center h-full">
                <div className="scanning-container">
                    <img
                        src={photoPreview}
                        alt="Scanning"
                        className={`scanned-image mx-auto ${isShortScreen ? 'h-[40vh]' : 'w-full'
                            } rounded object-contain`}
                    />
                    <div className="scanning-line"></div>
                </div>
                <div className='flex flex-col'>
                    <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={`font-serif text-xl md:text-2xl justify-center items-center py-2 px-4 md:py-3 md:px-6 rounded-full text-black text-center bg-white mt-4 ${showButton ? 'visible' : 'invisible'}`}
                        onClick={handleSeeAppraisal}
                    >
                        See Appraisal
                    </Button>
                    <Button
                        variant="text"
                        component="span"
                        className={`font-serif text-black text-center mt-2 ${cancel ? 'visible' : 'invisible'}`}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </Box>
            {
                openDialog && (
                    <ResponsiveDialog open={openDialog} setOpen={setOpenDialog} />
                )
            }
        </div >

    );
};

export default UploadView;
