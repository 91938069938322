import { Typography } from '@mui/material';
import React from 'react';
import PdfVeiwer from '../../components/PdfVeiwer';
import useWindowSize from '../../hooks/useWindowSize';


const PrivacyPolicy = () => {
    const privacyURL = '/files/WhatsItWorthPrivacyPolicy.pdf';
    const { width } = useWindowSize();
    const isMobile = width < 768;

    return (
        <div className="container mt-24">
            <Typography variant={isMobile ? 'h4' : 'h2'} className="font-serif">Privacy Policy</Typography>
            <PdfVeiwer pdfURL={privacyURL} />
        </div>
    );
}

export default PrivacyPolicy