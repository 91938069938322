import React, { useState, useEffect } from "react";
import { Button, TextField, Checkbox, FormControlLabel, Box, Modal, IconButton, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import useWindowSize from '../../hooks/useWindowSize';

const benefitOptions = [
    { id: "markup", label: "I want to know how marked up this item is" },
    { id: "appraise", label: "I want to appraise the things I own" },
    { id: "sustainable", label: "I want to know about how sustainable this item is" },
    { id: "other", label: "Other" },
];

// Airtable configuration
const AIRTABLE_PERSONAL_ACCESS_TOKEN = 'patWqVHW2qTsrPQRw.d64a7e34adb17bbcb8480f3ce1e0fad1124bc9d29ed35beb75615ac7ad6c4821';
const AIRTABLE_BASE_ID = 'appnzGV7Wi91kROjv';
const AIRTABLE_TABLE_NAME = 'tblrs3VG0SipyQdOx';

export default function FeedbackForm({ open, setOpen }) {
    const [step, setStep] = useState(1);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
    const [benefits, setBenefits] = useState([]);
    const [otherBenefit, setOtherBenefit] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { width } = useWindowSize();
    const isMobile = width < 768;

    useEffect(() => {
        if (open) {
            resetForm();
        }
    }, [open]);

    const resetForm = () => {
        setStep(1);
        setRating(0);
        setComment("");
        setBenefits([]);
        setOtherBenefit("");
        setErrorMessage("");
        setSuccessMessage("");
    };

    const handleBenefitChange = (benefitId) => {
        setBenefits((prev) => {
            if (prev.includes(benefitId)) {
                return prev.filter((id) => id !== benefitId);
            } else if (prev.length < 2) {
                return [...prev, benefitId];
            }
            return prev;
        });
    };

    const handleSubmitStep1 = (e) => {
        e.preventDefault();
        if (rating === 0) {
            setErrorMessage("Please provide a rating before submitting.");
            return;
        }
        setStep(2);
    };

    const handleSubmitStep2 = async (e) => {
        e.preventDefault();
        const formData = {
            records: [
                {
                    fields: {
                        Rating: Number(rating),
                        Comment: comment,
                        Benefits: benefits.join(", "),
                        OtherBenefit: otherBenefit
                    }
                }
            ]
        };

        console.log('Submitting data:', JSON.stringify(formData, null, 2));

        try {
            const response = await fetch(`https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${AIRTABLE_PERSONAL_ACCESS_TOKEN}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const responseData = await response.json();

            if (!response.ok) {
                console.error('Response status:', response.status);
                console.error('Response data:', JSON.stringify(responseData, null, 2));
                throw new Error(`API error: ${response.status} ${responseData.error?.message || 'Unknown error'}`);
            }

            console.log('Success:', responseData);
            setSuccessMessage("Thank you for your feedback!");
            setTimeout(() => {
                resetForm();
                setOpen(false);
            }, 2000);
        } catch (error) {
            console.error('Error details:', error);
            setErrorMessage(`There was an error submitting your feedback: ${error.message}`);
        }
    };

    const handleClose = () => {
        resetForm();
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="feedback-modal"
            aria-describedby="feedback-form"
        >
            <Box sx={{
                maxWidth: isMobile ? '90%' : '28rem',
                width: isMobile ? '90%' : 'auto',
                mx: 'auto',
                my: isMobile ? '2rem' : '5rem',
                p: isMobile ? 2 : 3,
                pt: isMobile ? 5 : 3,
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 3,
                position: 'relative',
                maxHeight: isMobile ? 'calc(100% - 4rem)' : 'auto',
                overflowY: 'auto'
            }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {successMessage ? (
                    <Typography variant="h6" align="center" className="font-serif">
                        {successMessage}
                    </Typography>
                ) : (
                    <>
                        {errorMessage && (
                            <Typography color="error" className="mb-4">
                                {errorMessage}
                            </Typography>
                        )}


                        <>
                            {step === 1 && (
                                <form onSubmit={handleSubmitStep1}>
                                    <Box className="mb-4">
                                        <h6 className="text-lg md:text-xl font-serif mb-2">How well does this result line up with your expectations?</h6>
                                        <Box className="flex justify-center mb-4">
                                            {[1, 2, 3, 4, 5].map((value) => (
                                                <Button
                                                    key={value}
                                                    onClick={() => setRating(value)}
                                                    sx={{ p: 0.5 }}
                                                >
                                                    <StarIcon
                                                        sx={{
                                                            width: isMobile ? 28 : 32,
                                                            height: isMobile ? 28 : 32,
                                                            color: value <= rating ? 'warning.main' : 'action.disabled'
                                                        }}
                                                    />
                                                </Button>
                                            ))}
                                        </Box>
                                    </Box>

                                    <Box className="mb-4">
                                        <h6 className="text-lg md:text-xl font-serif mb-2">Please explain your rating...</h6>
                                        <TextField
                                            id="comment"
                                            placeholder="Please explain your rating..."
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            multiline
                                            rows={isMobile ? 3 : 4}
                                            fullWidth
                                            className="w-full"
                                        />
                                    </Box>

                                    <Button type="submit" variant="contained"
                                        className={`!font-serif !text-lg !md:!text-xl !justify-center !items-center !py-2 !px-4 font-bold !rounded-full !text-white !text-center !bg-black !mt-4 !w-full`}
                                    >Next</Button>
                                </form>
                            )}

                            {step === 2 && (
                                <form onSubmit={handleSubmitStep2}>
                                    <Box className="mb-4">
                                        <h6 className="text-lg md:text-xl font-serif mb-2">What is the primary benefit you're looking for? (Choose up to 2)</h6>
                                        <Box className="space-y-1">
                                            {benefitOptions.map((option) => (
                                                <FormControlLabel
                                                    key={option.id}
                                                    control={
                                                        <Checkbox
                                                            checked={benefits.includes(option.id)}
                                                            onChange={() => handleBenefitChange(option.id)}
                                                            disabled={benefits.length >= 2 && !benefits.includes(option.id)}
                                                        />
                                                    }
                                                    label={<span className="font-serif text-sm md:text-base">{option.label}</span>}
                                                />
                                            ))}
                                            {benefits.includes("other") && (
                                                <TextField
                                                    placeholder="Please specify other benefit"
                                                    value={otherBenefit}
                                                    onChange={(e) => setOtherBenefit(e.target.value)}
                                                    fullWidth
                                                    className="mt-2 w-full"
                                                />
                                            )}
                                        </Box>
                                    </Box>

                                    <Box className="flex gap-4">
                                        <Button variant="outlined" fullWidth onClick={() => setStep(1)} className="w-1/2 font-serif font-bold text-sm md:text-base border-black !text-black">
                                            Back
                                        </Button>
                                        <Button type="submit" variant="contained" fullWidth className="w-1/2 font-display font-bold text-sm md:text-base !bg-black !text-white">Submit Feedback</Button>
                                    </Box>
                                </form>
                            )}
                        </>
                    </>
                )}
            </Box>
        </Modal>
    );
}