import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { analyzeItem } from '../../api';
import './index.css';
import CurrencyDropdown from '../../components/currencyCode';
import { Button, TextField, Typography, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useWindowSize from '../../hooks/useWindowSize';
import { resizeAnalyzerImage } from '../../hooks/useResize';
import LoadingView from '../../components/Loading';
import ResultPage from '../Result';
import { getPhoto, deletePhoto, storePhoto } from '../../utils/cacheManager';

function Analyzer({ storedPhoto, setStoredPhoto }) {
    const [brand, setBrand] = useState('');
    const [country, setCountry] = useState('');
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState('USD');
    const [photo, setPhoto] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [result, setResult] = useState(() => {
        const storedResult = localStorage.getItem('analysisResult');
        return storedResult ? storedResult : '';
    });

    const { width } = useWindowSize();
    const isMobile = width < 768;
    const hasAnalyzed = useRef(false);
    const containerRef = useRef(null);

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            hasAnalyzed.current = false;

            try {
                let processedFile = file;
                if (file.size > 20 * 1024 * 1024) {
                    processedFile = await new Promise((resolve) => {
                        resizeAnalyzerImage(file, resolve);
                    });
                }

                const preview = URL.createObjectURL(processedFile);
                setPhoto({ file: processedFile, preview });

                // Store the photo in cache
                const photoUrl = await storePhoto(processedFile);
                setStoredPhoto({ url: photoUrl, name: file.name, type: file.type });

            } catch (error) {
                console.error('Error processing dropped file:', error);
                setResult('Error processing dropped file.');

            }
        }
    }, [setStoredPhoto]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop
    });

    const analyzePhoto = useCallback(async (file) => {
        setIsLoading(true);
        try {
            const result = await analyzeItem(brand, file, price, currency);
            setResult(result);
            setIsAnalyzing(true);

            localStorage.setItem('analysisResult', result);

        } catch (error) {
            console.error('Error analyzing item:', error);
            setResult('Error analyzing item.');
        } finally {
            setIsLoading(false);
            setIsAnalyzing(false);
        }
    }, [brand, currency, price]);

    useEffect(() => {
        const processStoredPhoto = async () => {
            if (storedPhoto && !hasAnalyzed.current) {
                hasAnalyzed.current = true;
                try {
                    let photoBlob = storedPhoto.blob;
                    if (!photoBlob) {
                        photoBlob = await getPhoto(storedPhoto.url);
                        if (!photoBlob) throw new Error('Photo not found in cache');
                    }

                    const file = new File([photoBlob], storedPhoto.name, { type: storedPhoto.type });
                    setPhoto({ file, preview: URL.createObjectURL(photoBlob) });
                    await analyzePhoto(file);
                    await deletePhoto(storedPhoto.url);
                    setStoredPhoto(null);
                } catch (error) {
                    console.error('Error processing stored photo:', error);
                    setResult('Error processing stored photo.');
                }
            }
        };

        processStoredPhoto();
    }, [storedPhoto, setStoredPhoto, analyzePhoto]);

    const handleAnalyzeItem = async () => {
        if (!photo || !photo.file) {
            alert('Please upload a photo first.');
            return;
        }
        await analyzePhoto(photo.file);
    };

    const handleCancel = async () => {
        setIsAnalyzing(false);
        setPhoto(null);
        setResult(null);
        setBrand('');
        setPrice('');
        setStoredPhoto(null);
        if (storedPhoto && storedPhoto.url) {
            await deletePhoto(storedPhoto.url);
        }
        localStorage.removeItem('analysisResult');

        if (photo && photo.preview) {
            URL.revokeObjectURL(photo.preview);
        }
    };

    const handleDelete = () => {
        setPhoto(null);

    };

    const handleReset = async () => {
        setResult('');
        localStorage.removeItem('analysisResult');
        if (storedPhoto && storedPhoto.url) {
            await deletePhoto(storedPhoto.url);
        }
        setPhoto(null);
        setBrand('');
        setPrice('');
        setStoredPhoto(null);
    };

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    };

    useEffect(() => {
        return () => URL.revokeObjectURL(photo?.preview);
    }, [photo]);



    return (
        <Box className={`flex flex-col ${!result && !isLoading && 'container'} ${isMobile ? 'h-[calc(100dvh-8rem)]' : 'h-full'}`}>
            {(isLoading && storedPhoto) || (isLoading && photo) ? (
                <Box id="photo-container" className="container mx-auto my-0 flex flex-col items-center justify-center h-full">
                    <div className="scanning-container relative flex items-center justify-center w-full h-full">
                        <div className="image-wrapper relative">
                            <img
                                src={photo?.preview || storedPhoto?.url || ''}
                                alt="Scanning"
                                className="scanned-image object-contain max-w-full max-h-[70vh] rounded"
                            />
                            <div className="scanning-line absolute left-0 w-full"></div>
                        </div>
                    </div>
                    {isAnalyzing && (
                        <Button
                            variant="text"
                            component="span"
                            className={`font-serif text-black text-center mt-4`}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    )
                    }

                </Box>
            ) : (isLoading && !storedPhoto) || (isLoading && !photo) ? <LoadingView />
                : (
                    <>
                        {(storedPhoto || result !== '') ? (
                            <ResultPage result={result} handleReset={handleReset} />
                        ) : (
                            <>
                                {photo ? (
                                    <Box ref={containerRef} className="mt-16 relative w-full h-[200px] overflow-hidden">
                                        <Box
                                            className={`absolute rounded-md shadow-md transition-all duration-300 ease-in-out hover:z-50 hover:scale-110 ${photo.length === 1 ? 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' : ''
                                                }`}
                                        >
                                            <img
                                                src={photo.preview}
                                                className="w-full h-full object-cover rounded-md"
                                                alt={photo.name}
                                            />
                                            <IconButton
                                                className="absolute top-1 right-1 bg-white opacity-70 hover:opacity-100"
                                                size="small"
                                                onClick={() => handleDelete(photo)}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box {...getRootProps()} className={`dropzone border-2 border-dashed border-gray-300 p-4 text-center rounded-md cursor-pointer ${isMobile ? "w-full" : "w-3/4 mx-auto"}`}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <Typography className="font-serif">Drop the Photo here...</Typography>
                                        ) : isMobile ? (
                                            <Typography className="font-serif">Click to select a photo</Typography>
                                        ) :
                                            (
                                                <Typography className="font-serif">Drop a photo here, or click to select a photo</Typography>
                                            )}
                                    </Box>
                                )}
                                <Box className="input-area mt-4 space-y-4">
                                    <Box>
                                        <TextField
                                            label="Brand Name (optional)"
                                            type="text"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            fullWidth
                                            variant="outlined"
                                            className='mb-2'
                                            InputProps={{
                                                style: {
                                                    fontFamily: 'font-serif',
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: 'font-serif',
                                                },
                                            }}
                                        />
                                        <div>
                                            <TextField
                                                label="Listed Price (optional)"
                                                type="number"
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                                fullWidth
                                                variant="outlined"
                                                className='w-2/4 mr-4'
                                                InputProps={{
                                                    style: {
                                                        fontFamily: 'font-serif',
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        fontFamily: 'font-serif',
                                                    },
                                                }}
                                            />
                                            <CurrencyDropdown selectedCurrency={currency} handleCurrencyChange={handleCurrencyChange} />
                                        </div>
                                    </Box>
                                </Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAnalyzeItem}
                                    className="font-serif mt-4 py-3 px-6 rounded-full  text-black text-center bg-white hover:bg-slate-200 w-full md:w-1/2 md:self-center"
                                >
                                    Analyze
                                </Button>
                            </>
                        )}
                    </>
                )
            }
        </Box>
    );
}

export default Analyzer;