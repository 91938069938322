import { Typography } from '@mui/material';
import React from 'react';
import PdfVeiwer from '../components/PdfVeiwer';
import useWindowSize from '../hooks/useWindowSize';

const TermsOfService = () => {
    const termsURL = '/files/WhatsItWorthTerms.pdf';
    const { width } = useWindowSize();
    const isMobile = width < 768;

    return (
        <div className="container mt-24">
            <Typography variant={isMobile ? 'h4' : 'h2'} className="font-serif">Terms Of Service</Typography>
            <PdfVeiwer pdfURL={termsURL} />
        </div>
    );
}

export default TermsOfService;