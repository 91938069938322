import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className="fixed bottom-0">
            <Button variant='text' className='text-gray-300 font-serif' onClick={() => navigate('/privacy-policy')}> Privacy Policy</Button>
            <Button variant='text' className='text-gray-300 font-serif' onClick={() => navigate('/terms-of-service')} > Terms of Service</Button>
        </footer >
    )
}

export default Footer;