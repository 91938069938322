// Cache configuration
const CACHE_NAME = 'photo-cache';
const MAX_CACHE_ITEMS = 50;
const MAX_CACHE_SIZE = 20 * 1024 * 1024; // 20 MB

async function openPhotoCache() {
    return await caches.open(CACHE_NAME);
}

export async function storePhoto(file) {
    const cache = await openPhotoCache();
    const photoBlob = await file.arrayBuffer();
    const photoResponse = new Response(photoBlob);
    const photoUrl = `/photos/${Date.now()}`;
    await cache.put(photoUrl, photoResponse);
    await cleanupCache();
    return photoUrl;
}

export async function getPhoto(photoUrl) {
    const cache = await openPhotoCache();
    const response = await cache.match(photoUrl);
    if (!response) return null;
    return response.blob();
}

export async function deletePhoto(photoUrl) {
    const cache = await openPhotoCache();
    await cache.delete(photoUrl);
}

export async function cleanupCache() {
    const cache = await openPhotoCache();
    const keys = await cache.keys();
    const cacheSize = await getCacheSize(cache);

    if (keys.length > MAX_CACHE_ITEMS || cacheSize > MAX_CACHE_SIZE) {
        const itemsToRemove = Math.max(
            keys.length - MAX_CACHE_ITEMS,
            Math.ceil((cacheSize - MAX_CACHE_SIZE) / (cacheSize / keys.length))
        );

        const sortedKeys = await sortKeysByAge(cache, keys);
        for (let i = 0; i < itemsToRemove; i++) {
            await cache.delete(sortedKeys[i]);
        }
    }
}

// Get the total size of the cache
export async function getCacheSize(cache) {
    const keys = await cache.keys();
    let size = 0;
    for (const request of keys) {
        const response = await cache.match(request);
        const blob = await response.blob();
        size += blob.size;
    }
    return size;
}

// Sort cache keys by age (oldest first)
export async function sortKeysByAge(cache, keys) {
    const keyAges = await Promise.all(
        keys.map(async (key) => {
            const response = await cache.match(key);
            const date = new Date(response.headers.get('date'));
            return { key, date };
        })
    );
    return keyAges
        .sort((a, b) => a.date - b.date)
        .map(({ key }) => key);
}