import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({ onLogout, isAuthenticated, isPaidAccount, setPhoto }) => {
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogoClick = useCallback((event) => {
        event.preventDefault();

        const destination = isPaidAccount && isAuthenticated ? '/analyze' :
            !isPaidAccount && isAuthenticated ? '/payment' : '/';

        if (destination === '/') {
            localStorage.removeItem('storedPhotoInfo');
            localStorage.removeItem('analysisResult');
            setPhoto(null);
        }

        navigate(destination);
    }, [isPaidAccount, isAuthenticated, navigate, setPhoto]);

    return (
        <header className="fixed top-0 left-0 w-full z-10 shadow bg-white bg-opacity-25 backdrop-blur-lg backdrop-filter dark:border dark:border-gray-100/10 dark:bg-boxdark-2">
            <div className={`flex flex-row items-center justify-between p-4 ${isMobile ? 'h-16' : 'h-20'}`}>
                <div className='flex items-center'>
                    <a
                        href="/"
                        onClick={handleLogoClick}
                        className="no-underline text-black"
                    >
                        <h1 className={`font-display ${isMobile ? 'text-2xl' : 'text-3xl'} ml-2`}>
                            What's it worth?
                        </h1>
                    </a>
                </div>
                {isAuthenticated && (
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={handleClose}
                                component={Link}
                                to="/profile"
                                sx={{
                                    fontFamily: 'Playfair Display SC, serif !important',
                                    '& .MuiTypography-root': { fontFamily: 'inherit' }
                                }}
                            >
                                Profile
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    onLogout();
                                }}
                                sx={{
                                    fontFamily: 'Playfair Display SC, serif !important',
                                    '& .MuiTypography-root': { fontFamily: 'inherit' }
                                }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;