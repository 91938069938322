import { auth } from './firebase';  // Import Firebase auth instance
import { onAuthStateChanged } from 'firebase/auth';

// const API_URL = 'http://127.0.0.1:5000';
const API_URL = process.env.REACT_APP_API_URL || "https://whats-it-worth-server-38dfe6b0d49b.herokuapp.com"

export async function analyzeItem(brand, photo, price, currency) {
    try {
        const formData = new FormData();
        if (brand) {
            formData.append('brand', brand);
        }
        if (photo) {
            formData.append('photo', photo);
        }

        if (price) {
            formData.append('price', price);
        }
        if (currency) {
            formData.append('currency', currency);
        }
        const response = await fetch(`${API_URL}/analyze`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.error('Error analyzing item:', error);
        return 'Error analyzing item.';
    }
}

export const createCheckoutSession = async (serviceType) => {
    const token = await getIdToken();
    try {
        const response = await fetch(`${API_URL}/api/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ serviceType })
        });

        if (!response.ok) {
            throw new Error('Failed to create checkout session');
        }

        const session = await response.json();
        return session;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const checkPaymentStatus = async (idToken) => {

    try {
        const response = await fetch(`${API_URL}/api/check-payment-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id_token: idToken })
        });

        if (!response.ok) {
            throw new Error('Failed to check payment status');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const authCallback = async (idToken) => {
    try {
        const response = await fetch(`${API_URL}/api/auth-callback`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id_token: idToken })
        });
        if (!response.ok) {
            throw new Error('Failed to authenticate with backend');
        }
        return response;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchUserProfile = async () => {
    try {
        const idToken = await getIdToken();
        const response = await fetch(`${API_URL}/api/user-profile`, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (err) {
        throw new Error(`Failed to fetch user profile: ${err.message}`);
    } finally {
    }
};

export const manageSubscription = async () => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error('User not authenticated');
        }

        const idToken = await user.getIdToken(true);
        const response = await fetch(`${API_URL}/api/manage-subscription`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.url) {
            window.location.href = result.url;
        } else {
            throw new Error('Failed to get Stripe Customer Portal URL');
        }
    } catch (err) {
        console.error('Error in manageSubscription:', err);
        throw err;
    }
};

export const handleDeleteAccount = async () => {

    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
        try {
            const idToken = await getIdToken();
            const response = await fetch(`${API_URL}/api/delete-account`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
            }
            await auth.signOut();
        } catch (err) {
            throw new Error(`Failed to delete account: ${err.message}`);
        }
    }
};

async function getIdToken() {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const token = await user.getIdToken(true); // Force refresh to get a fresh token
                    resolve(token);
                } catch (error) {
                    reject(error);
                }
            } else {
                reject(new Error('User not authenticated'));
            }
        });
    });
}