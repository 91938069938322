import "./index.css"
import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import ResponsiveDialog from '../../components/Dialog';
import { resizeImage } from '../../hooks/useResize';
import { storePhoto, getPhoto } from "../../utils/cacheManager";
import UploadView from "../../components/Upload";
import Carousel from "../../components/Carousel";
import Footer from "../../components/Footer"

const LandingPage = ({ isAuthenticated, isPaid, storedPhoto, setStoredPhoto }) => {
    const [showButton, setShowButton] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [photoPreview, setPhotoPreview] = useState(null);
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const isMobile = width < 768;

    useEffect(() => {
        const loadPhotoFromCache = async (url) => {
            try {
                const photoBlob = await getPhoto(url);
                if (photoBlob) {
                    const photoUrl = URL.createObjectURL(photoBlob);
                    setPhotoPreview(photoUrl);
                }
            } catch (error) {
                console.error('Error loading photo from cache:', error);
            }
        };

        if (storedPhoto) {
            loadPhotoFromCache(storedPhoto.url);
            setCancel(true);
        }
    }, [storedPhoto]);

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles.length === 0) return;

        const file = acceptedFiles[0];

        if (!file.type.startsWith('image/')) {
            alert('Please upload an image file.');
            return;
        }

        try {
            const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
            const resizedImage = await resizeImage(file, maxSizeInBytes);
            const photoUrl = await storePhoto(resizedImage);

            const newPhoto = { url: photoUrl, name: file.name, type: 'image/jpeg', size: resizedImage.size };
            setStoredPhoto(newPhoto);

            // Create a preview URL for immediate display
            const previewUrl = URL.createObjectURL(resizedImage);
            setPhotoPreview(previewUrl);

        } catch (error) {
            console.error('Error processing image:', error);
        }
    };

    const { getInputProps } = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop
    });

    useEffect(() => {
        return () => {
            if (photoPreview) {
                URL.revokeObjectURL(photoPreview);
            }
        };
    }, [photoPreview]);

    const handleCancel = () => {
        setStoredPhoto(null);
        setPhotoPreview(null);
        setShowButton(false);
        setCancel(false);

        if (photoPreview) {
            URL.revokeObjectURL(photoPreview);
        }
    };

    const handleSeeAppraisal = () => {
        if (isAuthenticated && isPaid) {
            navigate("/analyze");
        } else {
            navigate("/login");
        }
    };

    // const oldSlides = [
    //     {
    //         src: "/heroexample.png",
    //         alt: "zoomed in prada bag",
    //         path: "/example/asics-gel-1130",
    //         exampleId: "prada-bag"
    //     },
    //     {
    //         src: "/carousel2.png",
    //         alt: "asics shoes",
    //         path: "/example/antique-cabinet",
    //         exampleId: "antique-cabinet"
    //     },
    //     {
    //         src: "/carousel3.png",
    //         alt: "louis vuitton bag",
    //         path: "/example/kingsmith-walking-pad",
    //         exampleId: "walking-pad"
    //     }
    // ];


    const slides = [
        {
            src: "/murphy-bed-carousel.png",
            alt: "murphy bed",
            path: "/example/murphy-bed",
            exampleId: "murphy-bed"
        },
        {
            src: "/carousel2.png",
            alt: "asics shoes",
            path: "/example/antique-cabinet",
            exampleId: "antique-cabinet"
        },
        {
            src: "/couch-carousel.png",
            alt: "sofa with storage",
            path: "/example/sofa-with-storage",
            exampleId: "sofa-with-storage"
        }
    ];


    return (
        <div className={`flex flex-col min-h-screen ${!isMobile && "pt-20"}`}>
            <div className={`flex-grow flex flex-col items-center justify-center px-4`}>
                {photoPreview ? (
                    <UploadView
                        isAuthenticated={isAuthenticated}
                        photoPreview={photoPreview}
                        handleCancel={handleCancel}
                        handleSeeAppraisal={handleSeeAppraisal}
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        showButton={showButton}
                        setShowButton={setShowButton}
                        cancel={cancel}
                        setCancel={setCancel} />
                ) : (
                    <div className="w-full max-w-md text-center">
                        <Carousel slides={slides} />
                        <Typography variant="h5" className="font-display mb-4">
                            AI Fashion & Decor Appraiser
                        </Typography>
                        <Typography variant="body1" className="font-serif mb-6">
                            Ask AI before you buy
                        </Typography>
                        <input
                            {...getInputProps()}
                            id="file-input"
                            className="hidden"
                        />
                        <div className='flex flex-col'>
                            <label htmlFor="file-input">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    className={`font-serif text-lg md:text-xl py-2 px-4 md:py-3 md:px-6 rounded-full text-black text-center bg-white`}
                                >
                                    Upload a Screenshot
                                </Button>
                            </label>

                            {!isAuthenticated && (
                                <Button
                                    variant="text"
                                    className="text-black font-serif mt-4"
                                    onClick={() => navigate("/login")}
                                >
                                    Log In
                                </Button>
                            )}
                        </div>
                    </div>
                )}
                <Footer />
            </div>
            {openDialog && (
                <ResponsiveDialog open={openDialog} setOpen={setOpenDialog} />
            )}
        </div>
    );
};

export default LandingPage;
