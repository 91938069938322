import Resizer from 'react-image-file-resizer';

export const resizeImage = (file, maxSizeInBytes) => {
    return new Promise((resolve, reject) => {
        const resizeRecursive = (width, height, quality) => {
            Resizer.imageFileResizer(
                file,
                width,
                height,
                'JPEG',
                quality,
                0,
                (uri) => {
                    if (uri.size <= maxSizeInBytes) {
                        resolve(uri);
                    } else if (quality > 10) {
                        resizeRecursive(width, height, quality - 10);
                    } else if (width > 800 || height > 800) {
                        resizeRecursive(Math.floor(width * 0.8), Math.floor(height * 0.8), 85);
                    } else {
                        resolve(uri);
                    }
                },
                'blob',
                (err) => {
                    console.error('Image resize error:', err);
                    reject(err);
                }
            );
        };

        const img = new Image();
        img.onload = () => {
            resizeRecursive(img.width, img.height, 85);
        };
        img.onerror = () => {
            console.error('Error loading image');
            reject(new Error('Error loading image'));
        };
        img.src = URL.createObjectURL(file);
    });
};


export const resizeAnalyzerImage = (file, maxSizeInBytes) => {
    return new Promise((resolve, reject) => {
        const resizeRecursive = (width, height, quality) => {
            Resizer.imageFileResizer(
                file,
                width,
                height,
                'JPEG',
                quality,
                0,
                (uri) => {
                    if (uri.size <= maxSizeInBytes) {
                        resolve(uri);
                    } else if (quality > 10) {
                        resizeRecursive(width, height, quality - 10);
                    } else if (width > 800 || height > 800) {
                        resizeRecursive(Math.floor(width * 0.8), Math.floor(height * 0.8), 85);
                    } else {
                        resolve(uri);
                    }
                },
                'blob',
                (err) => {
                    console.error('Image resize error:', err);
                    reject(err);
                }
            );
        };

        const img = new Image();
        img.onload = () => {
            resizeRecursive(img.width, img.height, 85);
        };
        img.onerror = () => {
            console.error('Error loading image');
            reject(new Error('Error loading image'));
        };
        img.src = URL.createObjectURL(file);
    });
};