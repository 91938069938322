// src/AppWrapper.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider } from "@mui/material";
import { AuthProvider } from './AuthContext';
import App from './App';

function AppWrapper() {
    return (
        <AuthProvider>
            <StyledEngineProvider injectFirst>
                <Router>
                    <App />
                </Router>
            </StyledEngineProvider>
        </AuthProvider>
    );
}

export default AppWrapper;