import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from './firebase';
import { onAuthStateChanged } from "firebase/auth";
import { authCallback, checkPaymentStatus } from './api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isPaidAccount, setIsPaidAccount] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                try {
                    const token = await firebaseUser.getIdToken();
                    const response = await authCallback(token);
                    if (!response.ok) {
                        throw new Error('Failed to authenticate with this is social');
                    }
                    const paymentResponse = await checkPaymentStatus(token);
                    setIsPaidAccount(paymentResponse.isPaid);
                    setUser(firebaseUser);
                    setSubscriptionStatus(paymentResponse.subscriptionStatus);
                } catch (error) {
                    console.error('Authentication error:', error);
                    setUser(null);
                    setIsPaidAccount(false);
                    setSubscriptionStatus(null);
                }
            } else {
                setUser(null);
                setIsPaidAccount(false);
                setSubscriptionStatus(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const logout = async () => {
        try {
            await auth.signOut();
            setUser(null);
            setIsPaidAccount(false);
            setSubscriptionStatus(null);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, loading, isPaidAccount, subscriptionStatus, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};