import Select from '@mui/material/Select';
import React from 'react';

const CurrencyDropdown = ({ selectedCurrency, handleCurrencyChange }) => {
    return (
        <Select id="currency" value={selectedCurrency} onChange={handleCurrencyChange} className="currency-dropdown max-w-16" variant="standard">
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="MXN">MXN</option>
            <option value="JPY">JPY</option>
            <option value="GBP">GBP</option>
            <option value="AUD">AUD</option>
            <option value="CAD">CAD</option>
            <option value="CHF">CHF</option>
            <option value="CNY">CNY</option>
            <option value="SEK">SEK</option>
            <option value="NZD">NZD</option>
        </Select>
    )
}

export default CurrencyDropdown