import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Carousel = ({ slides }) => {
    const navigate = useNavigate();

    const NextArrow = ({ onClick }) => (
        <button
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 p-2 bg-white bg-opacity-50 rounded-full shadow-md hover:bg-opacity-75 transition-all duration-200 focus:outline-none"
            onClick={onClick}
        >
            <ArrowForwardIosIcon />
        </button>
    );

    const PrevArrow = ({ onClick }) => (
        <button
            className="absolute left-0 top-1/2 -translate-y-1/2 z-10 p-2 bg-white bg-opacity-50 rounded-full shadow-md hover:bg-opacity-75 transition-all duration-200 focus:outline-none"
            onClick={onClick}
        >
            <ArrowBackIosNewIcon />
        </button>
    );

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dotsClass: "slick-dots custom-dots",
    };

    return (
        <Slider className="w-10/12 mx-auto mb-16"{...carouselSettings}>
            {slides.map((slide, index) => (
                <div key={index}>
                    <Box
                        component="img"
                        className="shadow-lg mx-auto mb-6 w-full max-w-[400px] h-auto rounded-2xl cursor-pointer"
                        alt={slide.alt}
                        src={slide.src}
                        onClick={() => navigate(slide.path)}
                    />
                </div>
            ))}
        </Slider>

    )
}

export default Carousel